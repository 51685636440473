import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge, Button, Tooltip, Space,
} from 'antd';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import type { ProColumns } from '@ant-design/pro-components';
import { HighlightOutlined } from '@ant-design/icons';
import { PagingDataResponse } from '../../../../hooks/fetch';
import { getBudgeColor } from '../../../Common/Badge';
import Table from '../../../Common/Table';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import {
  useShiftGet, Shift, useShiftDelete, useShiftUpdate, Allowances,
} from '../../../../hooks/shift';
import Header from './Common/Header';
import {
  getValidSearchParams, getHoursBetween, getDaysBetween, capitalize,
} from '../../../../utils';
import TableWrapper from '../../../Common/Table/Wrapper';
import { useAuthState } from '../../../../store/auth';
import { isRoleEnough, UserRoles, UserStatus } from '../../../../enums/user';
import Action, { ActionProps, RequiredActionProps } from '../Common/Table/Action';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import Note from '../../Client/User/List/Note';
import { useMessageError } from '../../../../hooks/common';
import { pageDefault, pageSizeDefault } from '../../../../contstant';
import ShiftStatus, { applyStatuses, getShiftStatus } from '../../../../enums/shift';
import { MultiDayIcon } from '../../../Common/Icon';
import { useApplicantId } from '../../../../hooks/applicant';

import styles from './index.module.scss';

export interface ShiftItem extends Shift {
  urlPrefix?: string;
}

export const getRate = (record: Shift) => {
  if (record?.rate && record?.rateType && record?.rateType === 'hourly') {
    return `$${record.rate} per hour`;
  }
  if (record?.rate) {
    return `$${record.rate}`;
  }
  if (record?.rateRangeFrom && record?.rateRangeTo) {
    return `$${record?.rateRangeFrom} - $${record?.rateRangeTo}`;
  }

  return 'Not set';
};

export const shiftRole: ProColumns<ShiftItem> = {
  title: 'Role',
  dataIndex: 'locationRole',
  key: 'locationRole',
  className: styles.roleColumn,
  render: (_, record) => record?.locationRole?.name || 'Not set',
};

export const shiftStatus: ProColumns<ShiftItem> = {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
  render: (status) => (
    <Badge className={styles.status} color={getBudgeColor(getShiftStatus(status))} text={getShiftStatus(status)} />
  ),
};

export const shiftRate: ProColumns<ShiftItem> = {
  title: 'Rate',
  dataIndex: 'rate',
  key: 'rate',
  sorter: true,
  render: (_, record) => getRate(record),
};

export const shiftAllowances: ProColumns<ShiftItem> = {
  title: 'Allowance Total',
  dataIndex: 'Allowances',
  key: 'Allowances',
  render: (_, record) => (
    <Tooltip
      title={
        record?.allowances?.length ? (
          <Space direction="vertical" className={styles.tooltip} size="small">
            {record?.allowances?.map((item) => `${capitalize(item.type)} - ${item.amount}$`)}
          </Space>
        ) : null
      }
    >
      {`$${
        record?.allowances?.reduce(
          (acc: number, allowance: Allowances) => (allowance?.amount ? acc + Number(allowance?.amount) : acc + 0),
          0,
        ) || 0
      }`}
    </Tooltip>
  ),
};

export const shiftTimeColumn: ProColumns<ShiftItem> = {
  title: 'Shift Time',
  dataIndex: 'datetime',
  key: 'datetime',
  sorter: true,
  className: styles.shiftColumn,
  render: (_, record) => (
    <Tooltip
      title={(
        <Space direction="vertical" className={styles.tooltip} size="small">
          {record.multiShift && (
            <Space className={styles.multiDayRow}>
              <MultiDayIcon />
              Multi-day shift
            </Space>
          )}
          {record.completedEdit && (
            <Space className={styles.multiDayRow}>
              <HighlightOutlined />
              Shift has been edited
            </Space>
          )}
          {record.client?.name}
          {record.locationRole?.name}
          {record.multiShift
            ? getDaysBetween(record?.multiShift.datetimeStart, record?.multiShift.datetimeEnd)
            : getDaysBetween(record?.datetimeStart, record?.datetimeEnd)}
          {getHoursBetween(record?.datetimeStart, record?.datetimeEnd)}
        </Space>
      )}
    >
      <div className={styles.dateTime}>
        <div className={styles.timeWrapper}>
          <NavLink to={record.urlPrefix + record.id} className={styles.link}>
            {record.multiShift
              ? getDaysBetween(record?.multiShift.datetimeStart, record?.multiShift.datetimeEnd)
              : getDaysBetween(record?.datetimeStart, record?.datetimeEnd)}
          </NavLink>
          {getHoursBetween(record?.datetimeStart, record?.datetimeEnd)}
        </div>
        <span className={styles.multiDayIcon}>{record.multiShift && <MultiDayIcon />}</span>
        <span className={styles.multiDayIcon}>
          {record.completedEdit
         && <HighlightOutlined style={{ color: '#BFBFBF' }} />}
        </span>
      </div>
    </Tooltip>
  ),
};

const columnsApplicant: ProColumns<ShiftItem>[] = [
  shiftTimeColumn,
  shiftRole,
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
    sorter: true,
    render: (_, record) => record?.client?.name,
  },
  {
    title: 'Location ',
    dataIndex: 'location ',
    key: 'location ',
    render: (_, record) => record?.location?.name,
  },
  {
    title: 'Domain',
    dataIndex: 'domain',
    key: 'domain',
    render: (_, record) => record?.domain?.title,
  },
  shiftRate,
  shiftAllowances,
  shiftStatus,
];

const columnsApplicantOpenShift: ProColumns<ShiftItem>[] = [
  shiftTimeColumn,
  shiftRole,
  {
    title: 'Shift type',
    dataIndex: 'shiftType',
    key: 'shiftType',
    render: (type) => <span style={{ textTransform: 'capitalize' }}>{type}</span>,
  },
  shiftRate,
  shiftAllowances,
];

const columnsAdmin: ProColumns<ShiftItem>[] = [
  shiftTimeColumn,
  shiftRole,
  {
    title: 'Shift type',
    dataIndex: 'shiftType',
    key: 'shiftType',
    render: (type) => <span style={{ textTransform: 'capitalize' }}>{type}</span>,
  },
  shiftStatus,
  shiftRate,
  shiftAllowances,
  {
    title: 'Applicant',
    dataIndex: 'applicant',
    key: 'applicant',
    render: (_, record) => (
      <NavLink to={`/applicants/${record?.applicant?.id}`}>
        {(
          `${record?.applicant?.title || ''} `
          + `${record?.applicant?.user?.firstName || ''} `
          + `${record?.applicant?.user?.lastName || ''}`
        ).replace('  ', ' ')}
      </NavLink>
    ),
  },
];

interface ShiftListProps {
  isMine?: boolean;
  disableHeader?: boolean;
  domainId?: string;
  locationId?: string;
  openShift?: boolean;
}

function ShiftList({
  disableHeader, domainId, locationId, openShift,
}: ShiftListProps): React.ReactNode {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAuthState();
  const shiftGet = useShiftGet();
  const shiftDelete = useShiftDelete();
  const { open, contextHolder } = useSimpleModal();
  const [searchParams] = useSearchParams();
  const [checkedList, setCheckedList] = useState<(string | number)[]>([]);
  const shiftUpdate = useShiftUpdate();
  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);
  const isApplicant = user?.role === UserRoles.APPLICANT;
  const isClient = user?.role === UserRoles.MANAGER || user?.role === UserRoles.CLIENT_ADMIN;
  const applicant = useApplicantId();
  const isMine = isApplicant && !openShift;
  const [pagination, setPagination] = useState({
    page: pageDefault,
    pageSize: pageSizeDefault,
  });
  const columns = useMemo(() => {
    let result;

    if (isAdmin) {
      result = [
        ...columnsAdmin,
        {
          title: 'Notes',
          dataIndex: 'notes',
          key: 'notes',
          render: (_, { id, notes }) => <Note id={id} notes={notes} shiftUpdate={shiftUpdate} />,
        } as ProColumns<ShiftItem>,
      ];
    }

    if (isClient) {
      result = columnsAdmin;
    }

    if (isApplicant && openShift) {
      result = columnsApplicantOpenShift;
    }

    result = result || columnsApplicant;

    return locationId ? result.filter(({ key }) => key !== 'client') : result;
  }, [user?.role, openShift, locationId]);

  const getShifts = () => {
    const paramsWithoutTableProps = getValidSearchParams('*', searchParams);

    shiftGet.fetch({
      isMine,
      domain: domainId,
      location: locationId,
      ...paramsWithoutTableProps,
      page: Number(pagination.page),
      pageSize: Number(pagination.pageSize),
    });
  };

  const shiftsData = useMemo<PagingDataResponse<ShiftItem>>(() => {
    if (shiftGet.data && shiftGet.data.data && shiftGet.data.data.length) {
      return {
        ...shiftGet.data,
        data: shiftGet.data.data.map((items) => ({
          ...items,
          urlPrefix: pathname.split('/')[1] === '' ? 'shifts/' : '',
        })),
      } as PagingDataResponse<ShiftItem>;
    }

    return shiftGet.data as PagingDataResponse<ShiftItem>;
  }, [shiftGet.data?.data]);

  useEffect(() => {
    if (isApplicant) {
      applicant.fetch(undefined, user.applicant?.id);
    }
  }, []);

  useEffect(getShifts, [searchParams, shiftDelete.response, shiftUpdate.response, pagination]);

  useMessageError([shiftUpdate]);

  return (
    <TableWrapper>
      {checkedList.length ? (
        <Button
          type="primary"
          disabled={user?.status === UserStatus.PENDING}
          className={styles.applySelected}
          onClick={() => navigate(`/open-shifts/confirmation?${checkedList.map((id) => `shifts=${id}`).join('&')}`)}
        >
          Apply to selected
        </Button>
      ) : null}

      <Table<ShiftItem, RequiredActionProps<ShiftItem, string>, ActionProps<ShiftItem, string>>
        setPagination={setPagination}
        pagination={pagination}
        rowKey="id"
        {...(openShift && isApplicant
          ? {
            listCount: shiftsData?.data?.length,
            selectAll: (entity) => !applyStatuses.includes(entity.status as ShiftStatus)
                // eslint-disable-next-line max-len
                || !entity.experiences?.some((item) => applicant?.data?.experiences?.map(({ id: expId }) => expId)?.includes(item.id)),
            checkedList,
            setCheckedList,
          }
          : {})}
        search={false}
        headerTitle={disableHeader ? null : <Header />}
        actions={isAdmin || isClient || (isApplicant && openShift) ? Action : undefined}
        actionsProps={{
          checkedList,
          shiftUpdate,
          isEditable: true,
          open,
          hookAction: shiftDelete,
          title: (actionName) => `${actionName} shift`,
          // eslint-disable-next-line max-len
          content: (actionName, entity) => `Are you sure you want to ${actionName?.toLowerCase()} this shift (${entity.location.name})?`,
          openShift,
          applicant: isApplicant ? applicant?.data : undefined,
        }}
        columnsState={{ persistenceKey: 'pro-table-shift', persistenceType: 'localStorage' }}
        options={{
          reload: false,
          density: false,
          setting: !disableHeader,
        }}
        columns={columns}
        loading={shiftGet.loading}
        pagingData={shiftsData}
      />

      {contextHolder}
    </TableWrapper>
  );
}

ShiftList.defaultProps = {
  openShift: false,
  isMine: false,
  disableHeader: false,
  domainId: undefined,
  locationId: undefined,
};

export default ShiftList;
