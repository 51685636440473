import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  CloseOutlined, CopyOutlined, DeleteOutlined, EditOutlined, LoadingOutlined,
} from '@ant-design/icons';
import { App, Badge, Space } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import { Shift, useShiftDelete } from '../../../../../hooks/shift';
import { useMessageError } from '../../../../../hooks/common';
import ShiftStatus, {
  editStatuses,
  deleteStatuses,
  adminNotEditStatuses,
  getShiftStatus,
} from '../../../../../enums/shift';
import { getDaysBetween, getHoursBetween } from '../../../../../utils';
import { getBudgeColor } from '../../../../Common/Badge';
import { useRosterContext } from '../../Context';
import OptionsContent from '../OptionsContent';
import styles from './index.module.scss';

const shiftBoxWidth = 367;
const optionBoxWidth = 124;

interface DetailProps {
  shift: Shift | undefined;
}

function Detail({ shift }: DetailProps) {
  if (!shift) {
    return null;
  }

  return (
    <div className={styles.content}>
      <div className={styles.row}>
        <span>Domain</span>
        <span>{shift?.domain?.title}</span>
      </div>
      <div className={styles.row}>
        <span>Location</span>
        <span>{shift?.location?.name}</span>
      </div>
      <div className={styles.row}>
        <span>Shift Time</span>
        <span>
          <NavLink to={`/shifts/${shift?.id}`}>
            <Space direction="vertical" className={styles.time}>
              {shift.multiShift
                ? getDaysBetween(shift?.multiShift.datetimeStart, shift?.multiShift.datetimeEnd)
                : getDaysBetween(shift?.datetimeStart, shift?.datetimeEnd)}
              {getHoursBetween(shift?.datetimeStart, shift?.datetimeEnd)}
            </Space>
          </NavLink>
        </span>
      </div>
      <div className={styles.row}>
        <span>Status</span>
        <span>
          <Badge color={getBudgeColor(shift?.status)} text={getShiftStatus(shift?.status)} />
        </span>
      </div>
      <div className={styles.row}>
        <span>Role</span>
        <span>{shift.locationRole?.name}</span>
      </div>
      <div className={styles.row}>
        <span>Rate</span>
        <span>{shift.rate ? `$${shift.rate}` : '-'}</span>
      </div>
      <div className={styles.row}>
        <span>Applicant</span>
        <span>
          <NavLink to={`/applicants/${shift.applicant?.id}`}>
            {`${shift.applicant?.title || ''} ${shift.applicant?.user?.fullName || ''} `}
          </NavLink>
        </span>
      </div>
    </div>
  );
}

export default function ModalDetails() {
  const {
    selectedRef, setSelectedRef, isDayView, getShifts,
  } = useRosterContext();
  const refShiftModal = useRef<HTMLDivElement | null>(null);
  const refOptionModal = useRef<HTMLDivElement | null>(null);
  const shiftDelete = useShiftDelete();
  const navigate = useNavigate();
  const shift = selectedRef?.shift;
  const [showModal, setShowModal] = useState(false);
  const isSider = selectedRef?.place === 'sider';
  const { message } = App.useApp();

  useEffect(() => {
    setShowModal(false);
  }, [selectedRef]);

  const onCloseShiftModal = () => {
    if (isSider) {
      setSelectedRef(undefined);
    }
    setShowModal(false);
  };

  useEffect(() => {
    const shiftContent = isSider ? selectedRef?.ref?.current : selectedRef?.ref?.current?.closest('.shift_item');
    const calendarContent = shiftContent?.closest('.calendar_content');
    const layoutContent = shiftContent?.closest('#root');
    const siderContent = shiftContent?.closest('.sider_content');

    if (shiftContent && layoutContent) {
      const padding = 6;
      const dotsWidth = 40;
      const shiftModal = refShiftModal.current;
      const optionModal = refOptionModal.current;

      if (selectedRef && shiftModal) {
        Object.assign(shiftModal.style, {
          width: `${shiftBoxWidth}px`,
        });
      }

      const onResize = () => {
        if (layoutContent) {
          // shift_fake
          // isDayView

          let shiftRect = shiftContent.querySelector('.shift_long')
            ? shiftContent?.querySelector('.shift_long')?.getBoundingClientRect()
            : shiftContent.getBoundingClientRect();

          if (isDayView && shiftContent.querySelector('.shift_fake')) {
            shiftRect = shiftContent?.querySelector('.shift_fake')?.getBoundingClientRect();
          }

          const layoutRect = layoutContent.getBoundingClientRect();

          if (!isSider && optionModal && shiftRect) {
            let left = shiftRect.x + shiftRect.width + padding;

            if (left + optionBoxWidth > layoutRect.width) {
              left = shiftRect.x + shiftRect.width - dotsWidth - optionBoxWidth;
            }

            Object.assign(optionModal.style, {
              transform: `translate(${left}px, ${shiftRect.y}px)`,
            });
          }

          if (showModal && shiftModal && shiftRect) {
            let left = shiftRect.x + shiftRect.width + optionBoxWidth + padding;

            if (left > layoutRect.width) {
              left = shiftRect.x + shiftRect.width - dotsWidth - shiftBoxWidth - optionBoxWidth;
            } else if (left + shiftBoxWidth > layoutRect.width) {
              left = shiftRect.x + shiftRect.width - dotsWidth - shiftBoxWidth;
            }

            Object.assign(shiftModal.style, {
              transform: `translate(${left}px, ${shiftRect.y}px)`,
            });
            console.log(1);
          }

          if (isSider && shiftModal && shiftRect) {
            const modalHeight = shiftModal.getBoundingClientRect().height;

            let top = shiftRect.y + shiftRect.height + padding;
            const offsetBottom = layoutRect.height - top;

            if (modalHeight > offsetBottom && shiftRect.y > modalHeight) {
              top = shiftRect.y - modalHeight - padding;
            }

            Object.assign(shiftModal.style, {
              transform: `translate(12px, ${top}px)`,
            });
          }
        }
      };

      const closeModalOnClick = (e: Event) => {
        const path = e.composedPath();

        if (!path.includes(shiftModal!) && !path.includes(optionModal!)) {
          setSelectedRef(undefined);
        }
      };

      onResize();

      window.addEventListener('resize', onResize, false);
      window.addEventListener('scroll', onResize, false);

      if (layoutContent) {
        layoutContent.addEventListener('click', closeModalOnClick, false);
      }

      if (!isSider && calendarContent) {
        calendarContent.addEventListener('scroll', onResize, false);
      }

      if (isSider && siderContent) {
        siderContent.addEventListener('scroll', onCloseShiftModal, false);
      }

      return () => {
        window.removeEventListener('resize', onResize);
        window.removeEventListener('scroll', onResize);

        if (layoutContent) {
          layoutContent.removeEventListener('click', closeModalOnClick);
        }

        if (!isSider && calendarContent) {
          calendarContent.removeEventListener('scroll', onResize);
        }

        if (isSider && siderContent) {
          siderContent.removeEventListener('scroll', onCloseShiftModal);
        }
      };
    }

    return () => {
      // default
    };
  }, [selectedRef, showModal]);

  useEffect(() => {
    if (shiftDelete.data) {
      getShifts();
      setSelectedRef(undefined);
      message.info('Shift has been deleted successfully');
    }
  }, [shiftDelete.data]);

  useMessageError([shiftDelete]);

  return (
    <>
      {selectedRef && !isSider && (
        <div className={styles.options} ref={refOptionModal}>
          <OptionsContent setShowModal={setShowModal} />
        </div>
      )}
      {selectedRef && shift && (isSider || (!isSider && showModal)) ? (
        <div className={clsx(styles.shift)} ref={refShiftModal}>
          <div className={styles.header}>
            {(!adminNotEditStatuses.includes(shift?.status as ShiftStatus)
              || editStatuses.includes(shift?.status as ShiftStatus)) && (
              <EditOutlined onClick={() => navigate(`/shifts/${shift.id}/edit`)} />
            )}
            <CopyOutlined onClick={() => navigate(`/shifts/${shift.id}/copy`)} />
            {shift.status !== ShiftStatus.DELETE
              && (shiftDelete.loading ? (
                <LoadingOutlined spin />
              ) : (
                <DeleteOutlined onClick={() => shiftDelete.fetch(shift.id)} />
              ))}
            <CloseOutlined onClick={onCloseShiftModal} />
          </div>
          <Detail shift={shift} />
        </div>
      ) : null}
    </>
  );
}
