import { Row } from 'antd';
import { NavLink } from 'react-router-dom';
import ViewItem from '../ViewItem';
import { capitalize } from '../../../../utils';
import { useShiftContext } from '../../../Pages/Shift/Context';
import Documents from '../Documents';
import styles from './index.module.scss';

export default function DomainRoleDetails() {
  const {
    shift, isAdmin, isApplicant, fetchApplicant, applicant,
  } = useShiftContext();

  const addCurrency = (value: number | undefined | null, type?: string) => {
    if (typeof value === 'boolean') {
      return '';
    }

    if (value && type && type === 'hourly') {
      return `$${value} per hour`;
    }
    if (value) {
      return `$${value}`;
    }

    return '';
  };

  return (
    <div className={styles.wrapper}>
      {isApplicant ? (
        <>
          <Row gutter={[16, 0]}>
            <ViewItem text="Domain" span={12} value={shift?.domain?.title} />
            <ViewItem text="Client" span={12} value={shift?.client?.name} />
          </Row>
          <Row gutter={[16, 0]}>
            <ViewItem text="Role" span={12} value={shift?.locationRole?.name} />

            <ViewItem
              text="Amount"
              span={12}
              value={shift?.rate ? `$${shift?.rate}` : `$${shift?.rateRangeFrom} - $${shift?.rateRangeTo}`}
            />
          </Row>
          <Row gutter={[16, 0]}>
            <ViewItem
              text="Contact phone"
              span={12}
              node={<NavLink to={`tel:${shift?.client.phone}`}>{shift?.client.phone}</NavLink>}
            />
            <ViewItem
              text="Contact email"
              span={12}
              node={<NavLink to={`mailto:${shift?.client.email}`}>{shift?.client.email}</NavLink>}
            />
          </Row>
          <Row gutter={[16, 0]}>
            <ViewItem text="Location" span={12} value={shift?.location?.name} />
            <ViewItem text="Address" span={12} value={shift?.location?.address} />
          </Row>
          <Row gutter={[16, 0]}>
            <ViewItem text="Experience*" span={12} value={shift?.experiences.map((item) => item.title)} />
            {applicant && <Documents applicant={applicant} fetchApplicant={fetchApplicant} />}
          </Row>
          {shift?.additionalRequirements && (
            <Row gutter={[16, 0]}>
              <ViewItem span={12} text="Additional Requirements" value={shift.additionalRequirements} />
            </Row>
          )}
        </>
      ) : (
        <>
          <Row gutter={[16, 0]}>
            <ViewItem text="Domain*" value={shift?.domain?.title} />
            {isAdmin && <ViewItem text="Client*" value={shift?.client?.name} />}
            <ViewItem text="Location*" value={shift?.location?.name} />
          </Row>
          <Row gutter={[16, 0]}>
            <ViewItem text="Role*" value={shift?.locationRole?.name} />
            <ViewItem text="Rate Type*" value={shift?.rateType && capitalize(shift?.rateType)} />
            <ViewItem
              text="Amount*"
              value={
                shift?.rate
                  ? `${addCurrency(shift.rate, shift.rateType)}`
                  : `${addCurrency(shift?.rateRangeFrom)} - ${addCurrency(shift?.rateRangeTo)}`
              }
            />
          </Row>
          <Row gutter={[16, 0]}>
            <ViewItem text="Experience*" value={shift?.experiences.map((item) => item.title)} />
            <ViewItem text="Minimum Required Documents*" value={shift?.documents.map((item) => item.title)} />
            {shift?.inductions.length ? (
              <ViewItem text="Inductions" value={shift?.inductions?.map((item) => item.title)} />
            ) : null}
          </Row>
          {shift?.additionalRequirements && (
            <Row gutter={[16, 0]}>
              <ViewItem text="Additional Requirements" value={shift.additionalRequirements} />
            </Row>
          )}
        </>
      )}
    </div>
  );
}
