import React, {
  createContext, Dispatch, PropsWithChildren, SetStateAction, useEffect, useMemo, useState,
} from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useLocation } from 'react-router-dom';
import { UUID } from 'crypto';
import { Item, useMessageError } from '../../../../hooks/common';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { getValidSearchParams, ValidSearchParams } from '../../../../utils';
import {
  Shift,
  shiftsRosterGetParamList,
  ShiftsRosterGetParams,
  useShiftGetByApplicant,
  useShiftGetByRole,
} from '../../../../hooks/shift';
import {
  TimeSlot,
  timeSlotParamList,
  TimeSlotParams,
  useApplicantDayimeSlots,
  useApplicantTimeSlots,
} from '../../../../hooks/applicant';
import { dateFormatISO } from '../../../../contstant';
import { Action } from '../../../../enums/roster';
import { defaultTeam } from '../Common/Header/Team';
import { PagingDataResponse } from '../../../../hooks/fetch';

export const viewList: Item[] = [
  {
    label: 'Day',
    value: 'day',
  },
  {
    label: 'Week',
    value: 'week',
  },
  {
    label: '2 weeks',
    value: '2 weeks',
  },
  {
    label: 'Month',
    value: 'month',
  },
];

export function getClosestPastSunday(date: dayjs.Dayjs): dayjs.Dayjs {
  const daysUntilSunday = (date.day() + 7) % 7;
  const closestSunday = date.subtract(daysUntilSunday, 'day');

  return closestSunday.startOf('day');
}

export interface SelectedRefState {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  place: 'sider' | 'calendar';
  shift: GridShift;
}

const currentDate = dayjs();

type RosterContextProps = {
  data?: Row[];
  loading: boolean;
  loadingVariants: boolean;
  gridVariants: TimeSlot[] | null;
  selectedRef: SelectedRefState | undefined;
  setSelectedRef: Dispatch<SetStateAction<SelectedRefState | undefined>>;
  selectedShift: GridShift | null;
  setSelectedShift: (shift: GridShift | null) => void;
  view: string;
  setView: (value: string) => void;
  team: string;
  setTeam: (value: string) => void;
  period: Dayjs;
  setPeriod: (date: Dayjs) => void;
  days: Dayjs[];
  isApplicantPage: boolean;
  isDayView: boolean;
  grid: PagingDataResponse<GridRow> | null;
  setGrid: (value: PagingDataResponse<GridRow> | null) => void;
  size: number;
  siderData: Shift[] | null;
  setSiderData: (value: Shift[] | null) => void;
  assignModal: Shift | null;
  setAssignModal: (value: Shift | null) => void;
  getShifts: () => void;
};

const defaultValue: RosterContextProps = {
  data: undefined,
  loading: false,
  loadingVariants: false,
  gridVariants: null,
  selectedRef: undefined,
  setSelectedRef: () => {
    // default
  },
  selectedShift: null,
  setSelectedShift: () => {
    // default
  },
  view: viewList[2].value as string,
  setView: () => {
    // default
  },
  team: defaultTeam.value,
  setTeam: () => {
    // default
  },
  period: getClosestPastSunday(currentDate.utc()),
  setPeriod: () => {
    // default
  },
  days: [],
  isApplicantPage: false,
  isDayView: false,
  grid: null,
  setGrid: () => {
    // default
  },
  size: 14,
  siderData: null,
  setSiderData: () => {
    // default
  },
  assignModal: null,
  setAssignModal: () => {
    // default
  },
  getShifts: () => {
    // default
  },
};

function parseDate(paramsWithoutTableProps: ValidSearchParams, defaultDate: Dayjs): Dayjs {
  if (typeof paramsWithoutTableProps.period === 'string') {
    const searchDate = dayjs(paramsWithoutTableProps.period);

    if (searchDate.isValid()) {
      return searchDate;
    }
  }

  return defaultDate;
}

function createGridStructure(data: Row[], days: Dayjs[]): GridRow[] {
  const rows: GridRow[] = [];

  data.forEach((row) => {
    const cols: GridCol[] = [];

    days.forEach((day) => {
      cols.push({
        day,
        date: day.get('date'),
        shifts: {} as [],
      });
    });

    const { shifts, ...args } = row;

    rows.push({
      ...args,
      days: cols,
    });

    let rowId = 0;

    shifts
      .map((shift) => {
        if (shift.end.clone().startOf('date').valueOf() === shift.end.valueOf()) {
          // eslint-disable-next-line no-param-reassign
          shift.end = shift.end.clone().subtract(1, 'second');
        }

        const startFullDate = parseInt(shift.start.format('YYYYMMDD'), 10);
        const endFullDate = parseInt(shift.end.format('YYYYMMDD'), 10);

        return {
          ...shift,
          startFullDate,
          endFullDate,
          multiDay: endFullDate - startFullDate > 0,
          beforeStartDay: false,
          beforeEndDay: false,
        };
      })
      .sort((a, b) => {
        const aDiff = a.end.diff(a.start, 'seconds');
        const bDiff = b.end.diff(b.start, 'seconds');

        // eslint-disable-next-line no-nested-ternary
        return aDiff === bDiff ? 0 : aDiff > bDiff ? 1 : -1;
      })
      .sort((a, b) => {
        const aDiff = a.start.unix();
        const bDiff = b.start.unix();

        // eslint-disable-next-line no-nested-ternary
        return aDiff === bDiff ? 0 : aDiff > bDiff ? -1 : 1;
      })
      .sort((a) => (a.multiDay ? -1 : 1))
      .forEach((shift) => {
        cols.forEach((col, i) => {
          const rowDate = parseInt(col.day.format('YYYYMMDD'), 10);

          if (shift.startFullDate <= rowDate && rowDate <= shift.endFullDate) {
            const gridShift = {
              ...shift,
              empty: !(shift.startFullDate === parseInt(col.day.format('YYYYMMDD'), 10) || i === 0),
              beforeStartDay: shift.startFullDate !== rowDate,
              beforeEndDay: rowDate !== shift.endFullDate,
            };

            if (gridShift.empty) {
              // eslint-disable-next-line no-param-reassign
              col.shifts[rowId] = gridShift;
            } else {
              let id = 0;

              while (col.shifts[id]) {
                id++;
              }

              if (gridShift.multiDay) {
                rowId = id;
              }

              // eslint-disable-next-line no-param-reassign
              col.shifts[id] = gridShift;
            }
          }
        });

        rowId++;
      });

    cols.forEach((col) => {
      // eslint-disable-next-line no-param-reassign
      col.shifts = Object.keys(col.shifts)
        .map((v) => parseInt(v, 10))
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => (a === b ? 0 : a > b ? 1 : -1))
        .map((i) => col.shifts[i]);
    });

    if (cols.length === 1) {
      cols.forEach((col) => {
        // eslint-disable-next-line no-param-reassign
        col.shifts = [
          ...col.shifts.filter(({ multiDay }) => multiDay),
          ...col.shifts.filter(({ multiDay }) => !multiDay),
        ];
      });
    }
  });

  return rows;
}

const getISOFormat = (fomatedDate: Date) => dayjs(fomatedDate).format(dateFormatISO).slice(0, -6);

export interface ShiftDecorated extends Shift {
  start: Dayjs;
  end: Dayjs;
  time: string;
}

interface ApplicantTypes {
  hourlyRate: string;
  employmentType: string;
  contractedHoursPerWeek: number;
  user: {
    fullName: string;
  };
}
export interface Row extends ApplicantTypes {
  id: UUID;
  name: string;
  locationName: string;
  locationId: string;
  domainId: string;
  clientId: string;
  clientName: string;
  totalHours: string;
  shifts: ShiftDecorated[];
}

export interface GridShift extends ShiftDecorated {
  empty: boolean;
  startFullDate: number;
  endFullDate: number;
  multiDay: boolean;
  beforeStartDay: boolean;
  beforeEndDay: boolean;
  action?: Action;
}

export interface GridCol {
  day: Dayjs;
  date: number;
  shifts: GridShift[];
}

export interface GridRow extends ApplicantTypes {
  id: UUID;
  name: string;
  locationName: string;
  clientName: string;
  totalHours: string;
  days: GridCol[];
  locationId: string;
  domainId: string;
  clientId: string;
}

export interface Variant {
  start: Dayjs;
  end: Dayjs;
}

export interface GridVariantRow {
  [key: number]: Variant[];
}

export interface GridVariants {
  [key: string]: GridVariantRow;
}

function getTimePeriodRange(period: Date, view: string) {
  let from = new Date();
  let to = new Date();

  // eslint-disable-next-line default-case
  switch (view) {
    case 'day':
      from = new Date(new Date(new Date(period)).setHours(0, 0, 0, 0));
      to = new Date(new Date(new Date(period)).setHours(23, 59, 59));
      break;
    case 'week':
      from = new Date(period.setHours(0, 0, 0, 0));
      to = new Date(from.getTime() + 7 * 24 * 60 * 60 * 1000);
      break;
    case '2 weeks':
      from = new Date(period.setHours(0, 0, 0, 0));
      to = new Date(from.getTime() + 14 * 24 * 60 * 60 * 1000);
      break;
    case 'month':
      from = new Date(period.getFullYear(), period.getMonth(), 1);
      to = new Date(period.getFullYear(), period.getMonth() + 1, 1);
  }

  return { from, to };
}

export const RosterContext = createContext<RosterContextProps>(defaultValue);

function RosterProvider({ children }: PropsWithChildren) {
  const getTimeSlots = useApplicantTimeSlots();
  const getDayTimeSlots = useApplicantDayimeSlots();
  const getByRole = useShiftGetByRole();
  const getByApplicant = useShiftGetByApplicant();
  const [siderData, setSiderData] = useState<Shift[] | null>(null);
  const [assignModal, setAssignModal] = useState<Shift | null>(null);
  const [selectedRef, setSelectedRef] = useState<SelectedRefState | undefined>(undefined);
  const [gridVariants, setGridVariants] = useState<TimeSlot[] | null>(null);
  const [selectedShift, setSelectedShift] = useState<GridShift | null>(null);
  const [grid, setGrid] = useState<PagingDataResponse<GridRow> | null>(null);
  const [searchParams, setSearchParams, paramsWithoutTableProps] = useSearchParams();
  const searchPeriod = useMemo(() => parseDate(paramsWithoutTableProps, currentDate), []);
  const [view, setView] = useState<string>((paramsWithoutTableProps.view || viewList[2].value) as string);
  const [team, setTeam] = useState<string>((paramsWithoutTableProps.teams || defaultTeam.value) as string);
  const [period, setPeriod] = useState<Dayjs>(searchPeriod);
  const { pathname } = useLocation();
  const isApplicantPage = pathname.includes('/applicant');
  const isDayView = view === 'day';
  const shiftGet = isApplicantPage ? getByApplicant : getByRole;

  useEffect(() => {
    shiftGet.clearResponse();
  }, [view, team, period]);

  const size = useMemo(() => {
    switch (view) {
      case 'day':
        return 1;

      case 'week':
        return 7;

      case '2 weeks':
        return 14;

      default:
        return period.clone().endOf('month').get('date');
    }
  }, [view, period]);

  const days = useMemo(() => {
    const list: Dayjs[] = [];
    let date = period.clone();

    switch (view) {
      case 'day':
        list.push(date);
        break;

      case 'week':
        date = date.startOf('week');

        for (let i = 0; i < 7; i++) {
          list.push(date);
          date = date.add(1, 'day');
        }
        break;

      case '2 weeks':
        date = date.startOf('week');

        for (let i = 0; i < 14; i++) {
          list.push(date);
          date = date.add(1, 'day');
        }
        break;

      default: {
        // month
        const limit = date.endOf('month').get('date');

        date = date.startOf('month');

        for (let i = 0; i < limit; i++) {
          list.push(date);
          date = date.add(1, 'day');
        }
      }
    }

    return list;
  }, [view, period]);

  const getShifts = () => {
    if (searchParams.getAll('locations').length === 0 && searchParams.getAll('clients').length === 0) {
      return;
    }
    const props: ShiftsRosterGetParams = getValidSearchParams(shiftsRosterGetParamList, searchParams);

    if (!searchParams.get('pageSize')) {
      props.pageSize = 20;
    }

    if (searchParams.get('teams')) {
      props.teams = [team];
    }

    shiftGet.fetch(props);
  };

  useEffect(() => {
    if (!searchParams.get('period') || !searchParams.get('view')) {
      return;
    }

    getShifts();
  }, [searchParams]);

  useEffect(() => {
    if (shiftGet.data) {
      setGrid({
        data: createGridStructure(
          shiftGet.data.data.map<Row>((role) => ({
            ...role,
            shifts: role.shifts
              ? role.shifts.map((shift) => ({
                ...shift,
                start: shift.multiShift ? dayjs(shift.multiShift.datetimeStart) : dayjs(shift.datetimeStart),
                end: shift.multiShift ? dayjs(shift.multiShift.datetimeEnd) : dayjs(shift.datetimeEnd),
                time: `${dayjs(shift.datetimeStart).format('HH-mm')} - ${dayjs(shift.datetimeEnd).format('HH-mm')}`,
              }))
              : [],
          })),
          days,
        ),
        meta: shiftGet.data.meta,
      });
    }
  }, [setGrid, shiftGet.data, days]);

  useEffect(() => {
    if (typeof paramsWithoutTableProps.view === 'string') {
      setView(paramsWithoutTableProps.view);
    } else {
      setView(viewList[2].value as string);
    }
  }, [paramsWithoutTableProps]);

  useEffect(() => {
    setPeriod(searchPeriod);
  }, [searchPeriod]);

  useEffect(() => {
    if (selectedShift) {
      const { from, to } = getTimePeriodRange(
        view !== 'day' ? new Date(getClosestPastSunday(period).toISOString()) : new Date(period.toISOString()),
        view,
      );

      const params: TimeSlotParams = {
        ...getValidSearchParams(timeSlotParamList, searchParams),
        shiftTimeStart: selectedShift.datetimeStart,
        shiftTimeEnd: selectedShift.datetimeEnd,
        shiftId: selectedShift.id,
        copy: selectedShift.action === Action.COPY,
        from: getISOFormat(from),
        to: getISOFormat(to),
        view,
        isRoleView: !isApplicantPage,
        posting: selectedShift.action === Action.ASSIGN,
      };

      if (!isApplicantPage && selectedShift.applicant) {
        params.applicants = [selectedShift.applicant.id];
      }
      if (isDayView) {
        getDayTimeSlots.fetch(params);
      } else {
        getTimeSlots.fetch(params);
      }
    } else {
      setGridVariants(null);
    }
  }, [selectedShift, view, period]);

  useEffect(() => {
    if (getTimeSlots.data) {
      if (selectedShift?.action === Action.ASSIGN) {
        setGridVariants(
          getTimeSlots.data.map((slot) => ({
            ...slot,
            timeSlots: slot.timeSlots.filter((dey) => dey === selectedShift?.start.date()),
          })),
        );

        return;
      }

      setGridVariants(getTimeSlots.data);
    }
  }, [getTimeSlots.data]);

  useEffect(() => {
    if (getDayTimeSlots.data) {
      setGridVariants(getDayTimeSlots.data);
    }
  }, [getDayTimeSlots.data]);

  useEffect(() => {
    const { from, to } = getTimePeriodRange(
      view !== 'day' ? new Date(getClosestPastSunday(period).toISOString()) : new Date(period.toISOString()),
      view,
    );

    const params = {
      ...getValidSearchParams('*', searchParams),
      view,
      period: view !== 'day' ? getClosestPastSunday(period.utc()).toISOString() : period.utc().toISOString(),
      from: getISOFormat(from),
      to: getISOFormat(to),
      teams: team === 'all' ? [] : team,
    };

    setSearchParams({ ...params });
  }, [view, team, period]);

  useMessageError([getByApplicant, getByRole, getTimeSlots]);

  const state = useMemo<RosterContextProps>(
    () => ({
      selectedRef,
      setSelectedRef,
      selectedShift,
      setSelectedShift,
      view,
      setView,
      team,
      setTeam,
      period,
      setPeriod,
      days,
      isApplicantPage,
      isDayView,
      grid,
      setGrid,
      size,
      gridVariants,
      siderData,
      setSiderData,
      assignModal,
      setAssignModal,
      getShifts,
      loading: shiftGet.loading,
      loadingVariants: getDayTimeSlots.loading || getTimeSlots.loading,
      data: shiftGet.data?.data,
    }),
    [
      selectedShift,
      view,
      team,
      period,
      days,
      grid,
      gridVariants,
      selectedRef,
      shiftGet.data,
      shiftGet.loading,
      siderData,
      assignModal,
    ],
  );

  return <RosterContext.Provider value={state}>{children}</RosterContext.Provider>;
}

export default RosterProvider;

export const useRosterContext = (): RosterContextProps => React.useContext(RosterContext);
