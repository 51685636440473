import React, { useEffect, useState } from 'react';
import {
  Col, Form, Row, Input, App, Modal, Checkbox,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Levels from '../Levels';
import Specialities from '../Specialities';
import Documents from '../../Documents';
import Inductions from '../Inductions';
import { useLocationsRolesCreate } from '../../../../../hooks/locationsRoles';
import { useLocationContext } from '../../Context';
import RateType from '../RateType';
import Rate from '../Rate';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { useDocumentRequired } from '../../../../../hooks/document';
import Experiences from '../../../../Common/Experiences';
import { useMessageError } from '../../../../../hooks/common';

import styles from './index.module.scss';
import Breaks from '../Breaks';

type CreateRoleProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function CreateRole({ isModalOpen, setIsModalOpen }: CreateRoleProps) {
  const { open, contextHolder } = useSimpleModal();
  const createRole = useLocationsRolesCreate();
  const requiredDocuments = useDocumentRequired();
  const { location, fetch } = useLocationContext();
  const [inductionRequired, setInductionRequired] = useState(false);
  const [isChanging, setChanging] = useState(false);
  const [form] = Form.useForm();
  const { message } = App.useApp();

  const setDefaultFields = () => {
    form.setFieldsValue({
      level: '',
      speciality: '',
      rateType: '',
      rate: '',
      rateRangeFrom: '',
      rateRangeTo: '',
      notes: '',
      experiences: [],
      documents: [],
      inductions: [],
      lunchBreak: undefined,
    });
    setInductionRequired(false);
  };

  useEffect(() => {
    form.validateFields();
  }, [inductionRequired]);

  const createNewRole = () => {
    form
      .validateFields()
      .then(() => {
        const { customLunchBreak, ...values } = form.getFieldsValue();

        createRole.fetch({
          ...values,
          location: location?.id,
          documents: [...(values.documents || []), ...(requiredDocuments?.data?.map(({ id }) => id) || [])],
          // lunchBreak: (values.lunchBreak === 'custom' ? customLunchBreak : values.lunchBreak) || 0,
          // eslint-disable-next-line no-nested-ternary
          lunchBreak: Number.isInteger(values.lunchBreak)
            ? values.lunchBreak : values.lunchBreak === 'custom' ? customLunchBreak : null,
        });
      })
      .catch(() => null);
  };

  useEffect(() => {
    requiredDocuments.fetch();
  }, []);

  useEffect(() => {
    if (createRole.data) {
      setIsModalOpen(false);
      message.info('Role successfully created');
      fetch();
      setDefaultFields();
    }
  }, [createRole.data]);

  const onFieldsChange = (_: any, allFields: any[]) => {
    if (allFields.some((field) => field.errors.length)) {
      setChanging(() => false);
    } else {
      setChanging(() => true);
    }
  };

  useMessageError([createRole]);

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={createNewRole}
        okButtonProps={{ disabled: !isChanging, loading: createRole.loading }}
        centered
        destroyOnClose
        onCancel={() => {
          if (isChanging) {
            open({
              title: 'Unsaved changes',
              content: 'Are you sure you want to discard these changes?',
              cancelText: 'Cancel',
              okText: 'Discard changes',
              centered: true,
              onOk: () => {
                setIsModalOpen(false);
                setDefaultFields();
                setChanging(() => false);
              },
            });
          } else {
            setDefaultFields();
            setIsModalOpen(false);
          }
        }}
        className={styles.modal}
        closable={false}
        maskClosable={false}
        okText={(
          <>
            <PlusOutlined />
            {' '}
            Create role
          </>
        )}
      >
        <div className={styles.wrapper}>
          <Form form={form} layout="vertical" requiredMark={false} onFieldsChange={onFieldsChange}>
            <Row gutter={[16, 6]}>
              <Col span={12}>
                <Levels />
              </Col>
              <Col span={12}>
                <Specialities />
              </Col>
            </Row>
            <div className={styles.bordered}>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <RateType />
                </Col>
                <Col span={12}>
                  <Rate form={form} />
                </Col>
              </Row>

              <Experiences required />
              <Documents />

              <Form.Item>
                <Checkbox
                  checked={inductionRequired}
                  onChange={(e) => {
                    setInductionRequired(e.target.checked);
                    form.validateFields();
                  }}
                >
                  Induction required?
                </Checkbox>
              </Form.Item>

              {inductionRequired && <Inductions inductionRequired={inductionRequired} />}

              <Row gutter={[16, 6]}>
                <Breaks form={form} />
              </Row>

              <Form.Item name="notes" label="Additional Requirements">
                <Input.TextArea rows={3} />
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
      {contextHolder}
    </>
  );
}
