import {
  Space, Typography, Button, Tooltip, Input, Modal, Form, Badge, App,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { FieldData } from 'rc-field-form/lib/interface';
import { getBudgeColor } from '../../../../Common/Badge';
import { useSettingsContext } from '../Context';
import MoreInfo from '../MoreInfo';
import { useApplicantDelete, useApplicantEnable, useApplicantDeactivate } from '../../../../../hooks/applicant';
import { useMessageError } from '../../../../../hooks/common';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';
import { paramsToString } from '../../../../Layout/Menu';
import { getRouteParams } from '../../../../../routes';
import { useAuthState } from '../../../../../store/auth';

import styles from './index.module.scss';

export default function TopBar() {
  const {
    isChanging, onSave, applicant, loading, isAdmin, isVerified, reviewed, handleVerification,
  } = useSettingsContext();
  const { message } = App.useApp();
  const { user } = useAuthState();
  const deleteApplicant = useApplicantDelete();
  const enableApplicant = useApplicantEnable();
  const checkApplicant = useApplicantDeactivate();
  const isReviewed = Object.values(reviewed).every((item) => item);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isValid, setValid] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const showVerification = isAdmin && !isVerified && applicant?.user.status !== 'rejected';
  const { open, contextHolder } = useSimpleModal();
  const isActive = applicant?.user.status === 'active';
  const actionName = isActive ? 'Deactivate' : 'Activate';

  useEffect(() => {
    if (deleteApplicant.data) {
      message.success('Applicant successfully deactivated');

      navigate(`/applicants${paramsToString(user, getRouteParams('applicants', user))}`, { replace: true });
    }
  }, [deleteApplicant.data]);

  useEffect(() => {
    if (enableApplicant.data) {
      message.success('Applicant successfully activated');

      navigate(`/applicants${paramsToString(user, getRouteParams('applicants', user))}`, { replace: true });
    }
  }, [enableApplicant.data]);

  useEffect(() => {
    if (checkApplicant.data) {
      open({
        icon: isActive ? (
          <StopOutlined style={{ color: '#FF4D4F' }} />
        ) : (
          <CheckCircleOutlined style={{ color: COLOR_PRIMARY }} />
        ),
        title: !isActive ? 'Activate applicant' : 'Deactivate applicant',
        content: (
          <div>
            {!checkApplicant.data.success && isActive ? (
              <Typography.Paragraph strong>
                This Applicant has shifts in progress. Please resolve those shifts to disable this applicant.
              </Typography.Paragraph>
            ) : (
              <Typography.Paragraph>
                {`Are you sure you want to ${actionName.toLowerCase()}  this applicant?`}
              </Typography.Paragraph>
            )}
          </div>
        ),
        okButtonProps: { disabled: !checkApplicant.data.success && isActive },
        cancelText: 'Cancel',
        okText: actionName,
        centered: true,
        onOk: () => (isActive ? deleteApplicant.fetch(applicant?.id) : enableApplicant.fetch({}, applicant?.id)),
      });
    }
  }, [checkApplicant.data]);

  useMessageError([deleteApplicant, checkApplicant, enableApplicant]);

  return (
    <>
      <Space className={styles.wrapper}>
        <Space size="middle" align="center">
          {isAdmin && (
            <ArrowLeftOutlined
              className={styles.arrowBack}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/applicants${paramsToString(user, getRouteParams('applicants', user))}`);
              }}
            />
          )}
          <Typography.Title level={4} style={{ margin: 0 }}>
            {`${applicant?.title} ${applicant?.user?.firstName} ${applicant?.user?.lastName}`}
          </Typography.Title>
          {isAdmin && (
            <Badge
              className={styles.badge}
              style={{ textTransform: 'capitalize' }}
              color={getBudgeColor(applicant?.user.status)}
              text={applicant?.user.status}
            />
          )}
        </Space>

        {showVerification ? (
          <Space size="middle">
            <Button type="text" danger onClick={() => setModalOpen(true)} loading={loading}>
              Reject
            </Button>
            <MoreInfo />
            <Tooltip title={!isReviewed ? 'Please check all “Information reviewed” boxes' : null}>
              <Button
                type="primary"
                loading={loading}
                onClick={() => isReviewed && handleVerification('approve')}
                className={clsx({ [styles.disabled]: !isReviewed })}
              >
                Approve
              </Button>
            </Tooltip>
          </Space>
        ) : (
          <Space>
            {isAdmin && applicant?.user.status !== 'rejected' && (
              <Button
                danger={isActive}
                loading={deleteApplicant.loading || checkApplicant.loading}
                type="primary"
                onClick={() => checkApplicant.fetch(undefined, applicant?.id || '')}
              >
                {actionName}
              </Button>
            )}
            <Button type="primary" onClick={() => (onSave ? onSave() : null)} disabled={!isChanging} loading={loading}>
              Save
            </Button>
          </Space>
        )}
      </Space>

      <Modal
        title="Reason for rejection"
        open={isModalOpen}
        onOk={() => {
          handleVerification('reject', form.getFieldValue('reason'));
          setModalOpen(false);
        }}
        onCancel={() => setModalOpen(false)}
        okButtonProps={{ loading, disabled: !isValid }}
        okText="Send"
        className={styles.modal}
        closable={false}
        centered
      >
        <Form
          form={form}
          requiredMark={false}
          layout="vertical"
          onFieldsChange={(_: FieldData[], allFields: FieldData[]) => {
            if (allFields.every((field) => field?.errors?.length === 0)) {
              setValid(() => true);
            } else {
              setValid(() => false);
            }
          }}
        >
          <Form.Item name="reason" rules={[{ required: true, message: 'Should be at least one character long!' }]}>
            <Input.TextArea placeholder="Type here" rows={2} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </>
  );
}
