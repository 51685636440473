import React from 'react';
import {
  CopyOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, StopOutlined,
} from '@ant-design/icons';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ProCoreActionType } from '@ant-design/pro-utils/es/typing';
import { Button, Tooltip } from 'antd';
import clsx from 'clsx';
import {
  DefaultFetchError, FetchDelete, FetchSuccess, FetchUpdate,
} from '../../../../../../hooks/fetch';
import { UseSimpleModalProps } from '../../../../../Common/Modal/Simple';
import {
  Shift,
  useShiftCopy,
  SuccessUpdateShift,
  ShiftUpdateParams,
} from '../../../../../../hooks/shift';
import { useMessageError } from '../../../../../../hooks/common';
import ShiftStatus, {
  applyStatuses,
  editStatuses,
  notCanceledStatuses,
  adminNotEditStatuses,
} from '../../../../../../enums/shift';
import { isRoleEnough, UserRoles, UserStatus } from '../../../../../../enums/user';
import { useAuthState } from '../../../../../../store/auth';
import { Applicant } from '../../../../../../types/applicant';

import styles from './index.module.scss';

export interface RequiredActionProps<Data, Props> {
  checkedList?: (string | number)[];
  openShift?: boolean;
  isEditable: boolean;
  open: (props?: UseSimpleModalProps) => void;
  hookAction: FetchDelete<FetchSuccess, DefaultFetchError, Props>;
  title: (actionName: string, entity: Data) => string;
  content: (actionName: string, entity: Data) => string;
  shiftUpdate: FetchUpdate<SuccessUpdateShift, DefaultFetchError, ShiftUpdateParams>;
  applicant: Applicant | undefined;
}

export interface ActionProps<Data, Props> extends RequiredActionProps<Data, Props> {
  dom: React.ReactNode;
  entity: Data;
  index: number;
  action: ProCoreActionType;
}

function Action<Data = Shift, Props = string>(props: ActionProps<Data, Props>) {
  const { user } = useAuthState();

  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);
  const isApplicant = user?.role === UserRoles.APPLICANT;
  const isClient = user?.role === UserRoles.MANAGER || user?.role === UserRoles.CLIENT_ADMIN;

  if (isAdmin) {
    return <AdminFlow {...props} />;
  }

  if (isClient) {
    return <ClientFlow {...props} />;
  }

  if (isApplicant) {
    return <ApplicantFlow {...props} />;
  }

  return null;
}

Action.defaultProps = {
  openShift: false,
};

function AdminFlow<Data = Shift, Props = string>({
  entity,
  isEditable,
  open,
  hookAction,
  title,
  content,
  shiftUpdate,
}: ActionProps<Data, Props>) {
  const { pathname } = useLocation();
  const { user } = useAuthState();
  const { id, status } = entity as Shift;
  const shiftCopy = useShiftCopy(id);
  const urlPrefix = pathname.split('/')[1] === '' && user?.role !== UserRoles.APPLICANT ? 'shifts/' : '';

  useMessageError([shiftCopy]);

  const changeStatus = (e: React.MouseEvent<HTMLOrSVGElement>) => {
    e.preventDefault();

    const onOk = () => hookAction.fetch(id as Props);

    open({
      style: {
        top: 'calc((100vh - 184px) / 2)',
      },
      icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />,
      title: title('Delete', entity),
      content: content('Delete', entity),
      cancelText: 'Cancel',
      okText: 'Delete',
      onOk,
    });
  };

  const canceledShift = (e: React.MouseEvent<HTMLOrSVGElement>) => {
    e.preventDefault();

    const onOk = () => shiftUpdate.fetch(
      {
        status: ShiftStatus.CANCELLED,
      },
      id,
    );

    open({
      style: {
        top: 'calc((100vh - 184px) / 2)',
      },
      icon: <StopOutlined style={{ color: '#FF4D4F' }} />,
      title: title('Cancel', entity),
      content: content('cancel', entity),
      cancelText: 'Cancel',
      okText: 'Cancel shift',
      onOk,
    });
  };

  return (
    <>
      {(entity as Shift)?.status === ShiftStatus.DELETE ? null : (
        <Tooltip title="Edit">
          <NavLink
            className={adminNotEditStatuses.includes(status as ShiftStatus) ? styles.disabled : ''}
            to={`${urlPrefix}${id}/edit`}
          >
            <EditOutlined />
          </NavLink>
        </Tooltip>
      )}

      <Tooltip title="Copy">
        <NavLink to={`${urlPrefix}${id}/copy`}>
          <CopyOutlined disabled />
        </NavLink>
      </Tooltip>

      {(entity as Shift)?.status === ShiftStatus.DELETE ? null : (
        <>
          <Tooltip title="Delete">
            <DeleteOutlined onClick={changeStatus} />
          </Tooltip>
          {shiftUpdate.loading ? (
            <LoadingOutlined spin />
          ) : (
            <Tooltip title="Cancel">
              <StopOutlined
                className={notCanceledStatuses.includes(status as ShiftStatus) ? styles.disabled : ''}
                onClick={canceledShift}
              />
            </Tooltip>
          )}
        </>
      )}
    </>
  );
}

AdminFlow.defaultProps = Action.defaultProps;

function ClientFlow<Data = Shift, Props = string>({
  entity,
  isEditable,
  open,
  hookAction,
  title,
  content,
}: ActionProps<Data, Props>) {
  const { pathname } = useLocation();
  const { user } = useAuthState();
  const { id, status } = entity as Shift;
  const shiftCopy = useShiftCopy(id);
  const urlPrefix = pathname.split('/')[1] === '' && user?.role !== UserRoles.APPLICANT ? 'shifts/' : '';

  useMessageError([shiftCopy]);

  const changeStatus = (e: React.MouseEvent<HTMLOrSVGElement>) => {
    e.preventDefault();

    const onOk = () => hookAction.fetch(id as Props);

    open({
      style: {
        top: 'calc((100vh - 184px) / 2)',
      },
      icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />,
      title: title('Delete', entity),
      content: content('Delete', entity),
      cancelText: 'Cancel',
      okText: 'Delete',
      onOk,
    });
  };

  return (
    <>
      <Tooltip title="Edit">
        <NavLink
          className={!editStatuses.includes(status as ShiftStatus) ? styles.disabled : ''}
          to={`${urlPrefix}${id}/edit`}
        >
          <EditOutlined />
        </NavLink>
      </Tooltip>

      <Tooltip title="Copy">
        <NavLink to={`${urlPrefix}${id}/copy`}>
          <CopyOutlined />
        </NavLink>
      </Tooltip>

      <Tooltip title="Delete">
        <DeleteOutlined
          className={UserRoles.ROOT !== user?.role ? styles.disabled : ''}
          onClick={changeStatus}
        />
      </Tooltip>
    </>
  );
}

ClientFlow.defaultProps = Action.defaultProps;

function ApplicantFlow<Data = Shift, Props = string>({ entity, applicant }: ActionProps<Data, Props>) {
  const navigate = useNavigate();
  const {
    id, status, applicants, maxApplicantAmount, experiences,
  } = entity as Shift;
  const { user } = useAuthState();
  // eslint-disable-next-line max-len
  const isMaxApplicants = applicants?.length >= maxApplicantAmount ? 'The shift has reached the max number of applicants.' : false;
  // eslint-disable-next-line max-len
  const dontEnoughExp = !experiences?.some((item) => applicant?.experiences?.map(({ id: expId }) => expId)?.includes(item.id))
    ? 'Your experience does not meet the requirements for this shift.'
    : false;
  const isDisabled = !applyStatuses.includes(status as ShiftStatus)
    || !!isMaxApplicants
    || user?.status === UserStatus.PENDING
    || !!dontEnoughExp;

  return (
    <div>
      <Tooltip title={isMaxApplicants || dontEnoughExp}>
        <Button
          type="default"
          className={clsx({ [styles.disabledApply]: isDisabled })}
          onClick={() => !isDisabled && navigate(`/open-shifts/confirmation?shifts=${id}`)}
        >
          Apply
        </Button>
      </Tooltip>
    </div>
  );
}

ApplicantFlow.defaultProps = Action.defaultProps;

export default Action;
