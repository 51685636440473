import React, {
  createContext, useState, useEffect, PropsWithChildren,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useShiftId, Shift } from '../../../../hooks/shift';
import { useAuthState } from '../../../../store/auth';
import { UserRoles, isRoleEnough } from '../../../../enums/user';
import { Applicant } from '../../../../types/applicant';
import { useApplicantId } from '../../../../hooks/applicant';
import { useMessageError } from '../../../../hooks/common';
import { generateDefaultUrl } from '../../../../routes';

interface ShiftContextProps {
  isAdmin: boolean;
  isApplicant: boolean;
  shift: Shift | null;
  fetch: () => void;
  applicant: Applicant | null;
  fetchApplicant: () => void;
}

const defaultValue: ShiftContextProps = {
  isAdmin: false,
  isApplicant: false,
  shift: null,
  applicant: null,
  fetch: () => {
    // default
  },
  fetchApplicant: () => {
    // default
  },
};

export const ShiftContext = createContext<ShiftContextProps>(defaultValue);

function ShiftProvider({ children }: PropsWithChildren) {
  const applicantById = useApplicantId();
  const shiftById = useShiftId();
  const { user } = useAuthState();
  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);
  const clientId = user?.locationAdmin?.client.id || user?.manager?.client.id;
  const navigate = useNavigate();
  const isApplicant = user?.role === UserRoles.APPLICANT;
  const [applicant, setApplicant] = useState<Applicant | null>(null);
  const { shiftId } = useParams();
  const [shift, setShift] = useState<Shift | null>(null);

  const fetch = () => {
    shiftById.fetch(undefined, shiftId);
  };

  const fetchApplicant = () => {
    applicantById.fetch(undefined, user?.applicant?.id);
  };

  useEffect(() => {
    if (applicantById.data) {
      setApplicant(applicantById.data);
    }
  }, [applicantById.data]);

  useEffect(() => {
    if (isApplicant) {
      fetchApplicant();
    }
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (shiftById.data) {
      if (clientId && shiftById.data.client?.id !== clientId) {
        navigate(`/shifts/${generateDefaultUrl(user)}`);
      }

      setShift(shiftById.data);
    }
  }, [shiftById.data]);

  useMessageError([shiftById]);

  return (
    <ShiftContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isAdmin,
        isApplicant,
        shift,
        fetch,
        applicant,
        fetchApplicant,
      }}
    >
      {children}
    </ShiftContext.Provider>
  );
}

export default ShiftProvider;

export const useShiftContext = (): ShiftContextProps => React.useContext(ShiftContext);
