import {
  Input, Form, Select, Row, Col, Checkbox, Typography,
} from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useShiftActionContext } from '../../../Pages/Shift/Create/Context';
import { useLocationsGet } from '../../../../hooks/location';
import { useDomainsGet } from '../../../../hooks/domain';
import { useLocationsRolesGet, useLocationsRolesId } from '../../../../hooks/locationsRoles';
import { useExperiencesGet } from '../../../../hooks/experience';
import Loading from '../../Loading/index';
import { useDocumentsGet } from '../../../../hooks/document';
import Rate from '../Rate';
import { useClientsGet } from '../../../../hooks/client';
import { useMessageError } from '../../../../hooks/common';

import styles from './index.module.scss';
import ShiftStatus from '../../../../enums/shift';
import { intervalBreaks } from '../../../Pages/Location/Components/Breaks';

export type Select = {
  label: string;
  value: string;
};

export default function DomainRole() {
  const role = useLocationsRolesId();
  const locations = useLocationsGet();
  const domainsGet = useDomainsGet();
  const experiences = useExperiencesGet();
  const documents = useDocumentsGet();
  const locationRoles = useLocationsRolesGet();
  const clients = useClientsGet();
  const [searchParams] = useSearchParams();
  const {
    form, setFields, fields, isAdmin, mode, requiredDocumentsIds, shift,
  } = useShiftActionContext();
  const values = Form.useWatch([], form);
  const isEditMode = mode === 'edit';
  const isCompletedEdit = (isEditMode && shift && shift.status === ShiftStatus.COMPLETED) || false;

  useEffect(() => {
    domainsGet.fetch();
    experiences.fetch();
    documents.fetch();
  }, []);

  useEffect(() => {
    if (searchParams.get('locationRole')) {
      onChangeRole(searchParams.get('locationRole') || '');
    }
  }, [requiredDocumentsIds]);

  useEffect(() => {
    if (domainsGet.data) {
      // @ts-ignore Value of type '(state: any) => any' has no properties
      setFields((state) => ({
        ...state,
        domains: domainsGet?.data?.map(({ title, id }) => ({ label: title, value: id })),
      }));
    }
  }, [domainsGet.data]);

  useEffect(() => {
    if (experiences.data) {
      // @ts-ignore Value of type '(state: any) => any' has no properties
      setFields((state) => ({
        ...state,
        experiences: experiences?.data?.map(({ title, id }) => ({ title, value: id })),
      }));
    }
  }, [experiences.data]);

  useEffect(() => {
    if (documents.data) {
      // @ts-ignore Value of type '(state: any) => any' has no properties
      setFields((state) => ({
        ...state,
        documents: documents?.data?.map(({ title, id }) => ({ title, value: id })),
      }));
    }
  }, [documents.data]);

  useEffect(() => {
    if (clients.data) {
      // @ts-ignore Value of type '(state: any) => any' has no properties
      setFields((state) => ({
        ...state,
        clients: clients?.data?.data?.map((item) => ({
          label: item.name,
          value: item.id,
          autoApproved: item.autoApproved,
        })),
      }));
    }
  }, [clients.data]);

  useEffect(() => {
    if (values?.domain) {
      if (isAdmin) {
        clients.fetch({ domains: values?.domain, isActive: true });
      } else {
        locations.fetch({ domain: values?.domain || undefined, client: values?.client || undefined, isActive: true });
      }
    }
  }, [values?.domain]);

  useEffect(() => {
    if (values?.client) {
      locations.fetch({ domain: values?.domain || undefined, client: values?.client || undefined, isActive: true });
    }
  }, [values?.client]);

  useEffect(() => {
    if (locations.data) {
      setFields({ ...fields, locations: locations?.data?.data?.map((item) => ({ label: item.name, value: item.id })) });
    }
  }, [locations.data]);

  useEffect(() => {
    if (values?.location) {
      locationRoles.fetch({ location: values?.location });
    }
  }, [values?.location]);

  useEffect(() => {
    if (locationRoles.data) {
      setFields({ ...fields, roles: locationRoles?.data?.data?.map((item) => ({ label: item.name, value: item.id })) });
    }
  }, [locationRoles.data]);

  useEffect(() => {
    if (role.data) {
      const { data } = role;

      console.log((data.lunchBreak));

      form?.setFieldsValue({
        experiences: data.experiences?.map(({ id }) => id),
        documents: data.documents?.map(({ id }) => id)?.filter((id) => !requiredDocumentsIds?.includes(id)),
        rateType: data.rateType,
        rate: data.rate,
        rateRangeFrom: data.rateRangeFrom,
        rateRangeTo: data.rateRangeTo,
        additionalRequirements: data.notes,
        inductions: data.inductions?.map(({ id }) => id),
        // eslint-disable-next-line no-nested-ternary
        lunchBreak: Number.isInteger(data.lunchBreak)
          ? intervalBreaks.includes(data.lunchBreak) ? data.lunchBreak : 'custom' : undefined,
        customLunchBreak: data.lunchBreak || 0,
      });
      form?.validateFields();
    }
  }, [role.data]);

  const onChangeDomain = () => {
    form?.setFieldsValue({
      location: null,
      locationRole: null,
    });

    if (isAdmin) {
      form?.setFieldValue('client', null);
    }
  };

  const onChangeClient = () => {
    form?.setFieldsValue({
      location: null,
      locationRole: null,
    });
  };

  const onChangeLocation = () => {
    form?.setFieldsValue({
      locationRole: null,
    });
  };

  const onChangeRole = (value: string) => {
    role.fetch(undefined, value);
  };

  useMessageError([role, locations, domainsGet, experiences, documents, locationRoles, clients]);

  return (
    <div>
      <Row gutter={[16, 0]}>
        <Col span={12} lg={8}>
          <Form.Item name="domain" label="Domain*" rules={[{ required: true, message: 'Required field!' }]}>
            <Select
              placeholder="Please select"
              loading={domainsGet.loading}
              options={domainsGet?.data?.map((item) => ({ label: item.title, value: item.id }))}
              onChange={onChangeDomain}
              disabled={isEditMode || domainsGet.loading}
            />
          </Form.Item>
        </Col>
        {isAdmin && (
          <Col span={12} lg={8}>
            <Form.Item name="client" label="Client*" rules={[{ required: true, message: 'Required field!' }]}>
              <Select
                disabled={!values?.domain || isEditMode || clients.loading}
                placeholder="Please select"
                loading={clients.loading}
                options={clients?.data?.data?.map((item) => ({ label: item.name, value: item.id }))}
                onChange={onChangeClient}
              />
            </Form.Item>
          </Col>
        )}
        <Col span={12} lg={8}>
          <Form.Item name="location" label="Location*" rules={[{ required: true, message: 'Required field!' }]}>
            <Select
              disabled={
                (!isAdmin && !values?.domain) || (isAdmin && !values?.client) || isEditMode || locations.loading
              }
              placeholder="Please select"
              options={locations?.data?.data?.map((item) => ({ label: item.name, value: item.id }))}
              loading={locations.loading}
              onChange={onChangeLocation}
            />
          </Form.Item>
        </Col>

        {!isAdmin && (
          <Col span={0} lg={8}>
            <Form.Item name="client" label="Client*" hidden>
              <Input />
            </Form.Item>
          </Col>
        )}

        <Col span={12} lg={8}>
          <Form.Item name="locationRole" label="Role*" rules={[{ required: true, message: 'Required field!' }]}>
            <Select
              placeholder="Please select"
              disabled={!values?.location || locationRoles.loading || isCompletedEdit}
              options={locationRoles?.data?.data?.map((item) => ({ label: item.name, value: item.id }))}
              loading={locationRoles.loading}
              onChange={onChangeRole}
            />
          </Form.Item>
        </Col>
        <Col span={12} lg={8}>
          <Form.Item name="rateType" label="Rate Type*" rules={[{ required: true, message: 'Required field!' }]}>
            <Select
              placeholder="Please select"
              disabled={!values?.locationRole || isCompletedEdit}
              options={[
                { value: 'hourly', label: 'Hourly' },
                { value: 'fixed', label: 'Fixed ' },
                { value: 'range', label: 'Range ' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12} lg={8}>
          <Rate form={form!} />
        </Col>
      </Row>

      {experiences.loading ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : (
        experiences.data && (
          <Row gutter={[16, 5]}>
            <Col span={24} lg={8}>
              <Form.Item
                name="experiences"
                label="Applicant Experience*"
                rules={[{ required: true, message: 'Required field!' }]}
              >
                <Select
                  disabled={isCompletedEdit}
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={experiences.data?.map(({ title, id }) => ({ label: title, value: id }))}
                />
              </Form.Item>
            </Col>
          </Row>
        )
      )}

      {documents.loading ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : (
        documents.data && (
          <Row gutter={[16, 5]}>
            <Col span={24}>
              <Typography.Text strong>Minimum Required Documents*</Typography.Text>
            </Col>
            <Col span={24}>
              <Checkbox checked disabled>
                Resume (always required)
              </Checkbox>
            </Col>
            <Col span={24} lg={8}>
              <Form.Item name="documents">
                <Select
                  disabled={isCompletedEdit}
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={documents.data
                    ?.map(({ title, id }) => ({ label: title, value: id }))
                    .filter(({ label }) => label.toLowerCase() !== 'resume')}
                />
              </Form.Item>
            </Col>
          </Row>
        )
      )}

      <Form.Item name="additionalRequirements" label="Additional Requirements">
        <Input.TextArea disabled={isCompletedEdit} rows={2} placeholder="Type here" />
      </Form.Item>
    </div>
  );
}
