import { Typography, Space } from 'antd';
import clsx from 'clsx';
import styles from './index.module.scss';

type EarningsProps = {
  earning: string;
  title: string;
  marginBottom?: boolean;
};

function Earnings({ earning, title, marginBottom }: EarningsProps) {
  return (
    <Space direction="vertical" className={clsx(styles.layout, marginBottom ? styles.marginBottom : null)}>
      <Typography.Title level={5}>{title}</Typography.Title>
      <span className={styles.earning}>
        $
        {earning}
      </span>
    </Space>
  );
}

Earnings.defaultProps = {
  marginBottom: false,
};

export default Earnings;
