import {
  Form, InputNumber, FormInstance, Space,
} from 'antd';
import replaseToDollar from '../../../../../utils/regular';

interface RateProps {
  form: FormInstance;
}

export default function Rate({ form }: RateProps) {
  const values = Form.useWatch([], form);

  return (
    <div>
      {values?.rateType === 'range' ? (
        <Space>
          <Form.Item name="rateRangeFrom" label="From">
            <InputNumber
              placeholder="From"
              formatter={(value) => `$ ${value}`.replace(replaseToDollar, ',')}
              controls={false}
              style={{ width: '100%' }}
              max={values.rateRangeTo}
            />
          </Form.Item>
          <Form.Item name="rateRangeTo" label="To">
            <InputNumber
              placeholder="To"
              min={values?.rateRangeFrom}
              formatter={(value) => `$ ${value}`.replace(replaseToDollar, ',')}
              controls={false}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Space>
      ) : (
        <Form.Item name="rate" label=" ">
          <InputNumber
            placeholder="$100"
            formatter={(value) => `$ ${value}`.replace(replaseToDollar, ',')}
            controls={false}
            style={{ width: '100%' }}
          />
        </Form.Item>
      )}
    </div>
  );
}
