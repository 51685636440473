import { Row } from 'antd';
import { useEffect } from 'react';
import ViewItem from '../ViewItem';
import Inductions from '../Inductions';
import { useShiftContext } from '../../../Pages/Shift/Context';
import { useOrientationId } from '../../../../hooks/orientation';
import OrientationDocuments from '../OrientationDocuments';
import styles from './index.module.scss';

export default function ApplicantDetails() {
  const orientation = useOrientationId();
  const { fetchApplicant, applicant, shift } = useShiftContext();
  const thisApplicantChoosen = applicant && applicant?.id === shift?.applicant?.id;

  useEffect(() => {
    if (thisApplicantChoosen) {
      orientation.fetch(undefined, shift?.orientation.id);
    }
  }, [thisApplicantChoosen]);

  return (
    <div className={styles.wrapper}>
      <Row gutter={[16, 0]}>
        {thisApplicantChoosen && orientation.data && <OrientationDocuments documents={orientation?.data?.files} />}
        <Inductions applicant={applicant} title="Inductions Completed?" fetchApplicant={fetchApplicant} />
        {thisApplicantChoosen && orientation?.data?.message && (
          <ViewItem text="Orientation notes" span={12} value={orientation?.data?.message} />
        )}
        {shift?.additionalRequirements && (
          <ViewItem text="Additional requirements*" span={12} value={shift?.additionalRequirements} />
        )}
      </Row>
    </div>
  );
}
