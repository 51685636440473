import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button, Col, Form, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SignUpPageDefaultProp } from '../../types';
import { useContextSignUp } from '../../Context';
import InputFile from '../../../../Common/Input/File';
import { useDocumentsGet } from '../../../../../hooks/document';
import { useAuthState } from '../../../../../store/auth';
import styles from '../Experience/index.module.scss';
import Loading from '../../../../Common/Loading';
import { getMessageInError } from '../../../../../hooks/fetch';

interface FormValues {
  resume: string
  certificate: string
  children: string
  clearance: string
}

type DocumentsProps = SignUpPageDefaultProp

export default function Documents({
  name, className,
}: DocumentsProps): React.ReactNode | null {
  const [form] = Form.useForm();
  const documents = useDocumentsGet({ signUp: true });
  const { authorized } = useAuthState();
  const [loadingList, setLoadingList] = useState<{[key:number]: boolean}>({});
  const {
    setForm, onFinish, triggerBack, isPossibleTriggerBack,
  } = useContextSignUp();
  const isLoadingFiles = Object.keys(loadingList).filter((i) => loadingList[parseInt(i, 10)]).length > 0;

  useEffect(() => {
    if (authorized) {
      documents.fetch();
    }
  }, [authorized]);

  useEffect(() => {
    setForm(name, form);
  }, [name, form]);

  const onFinishHandler = (values: FormValues) => {
    onFinish(name, values);
  };

  return (
    <Form<FormValues>
      form={form}
      className={className}
      name={name}
      autoComplete="off"
      layout="vertical"
      onFinish={onFinishHandler}
    >
      <h2>Thank you for your interest in Lokem!</h2>
      <span>Please complete the questions below</span>
      <Row gutter={24}>
        {documents.loading || !documents.data ? (
          <div className={styles.loading}>
            <Loading />
          </div>
        ) : (
          (documents.data || []).map(({ id, title, isRequired }, i) => (
            <Col span={24} key={id}>
              <span>
                {title}
                {isRequired ? ' (Required)' : null}
              </span>
              <Form.Item
                name={id}
                rules={[{ required: isRequired, message: 'Required resume file!' }]}
              >
                <InputFile
                  i={i}
                  size="large"
                  name={id}
                  form={form}
                  setLoadingList={setLoadingList}
                />
              </Form.Item>
            </Col>
          ))
        )}
      </Row>

      {documents.error ? (
        <Alert
          type="error"
          message={getMessageInError(documents.error)}
          closable
          onClose={documents.clearError}
          style={{ marginBottom: 16, marginTop: -16 }}
        />
      ) : null}

      <div>
        {isPossibleTriggerBack() ? (
          <Button onClick={triggerBack} size="large">
            Go Back
          </Button>
        ) : null}
        <Form.Item shouldUpdate className="submit">
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={documents.loading || isLoadingFiles}
              disabled={
                documents.loading
                || isLoadingFiles
                || (documents.data || [])
                  .filter(({ isRequired }) => isRequired)
                  .filter(({ id }) => !form.getFieldValue(id)).length !== 0
                || form.getFieldsError().filter(({ errors }) => errors.length).length > 0
              }
            >
              Save and Continue
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
}
