import { List } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { useEffect } from 'react';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';
import { useSettingsContext } from '../Context';
import Wrapper from '../../../../Common/Applicant/Wrapper';
import { useDocumentsGet } from '../../../../../hooks/document';
import UploadAction from '../../../../Common/Applicant/UploadAction';
import ViewAction from '../../../../Common/Applicant/ViewAction';
import Reviewed from '../../../../Common/Applicant/Reviewed';
import Notes from '../../../../Common/Applicant/Notes';

import styles from './index.module.scss';

export default function Documents() {
  const documentsGet = useDocumentsGet({ signUp: true });
  const {
    applicant, fetch, setActiveTab, applicantId,
  } = useSettingsContext();

  useEffect(() => {
    setActiveTab(() => 'documents');
    documentsGet.fetch();
  }, []);

  const getActions = (typeId: string) => {
    if (applicant) {
      const document = applicant.documents?.find((doc) => doc.documentType?.id === typeId);

      return (
        <>
          {document && (
            <span>
              <ViewAction fileId={document.file.id} name={document.file.originalName} />
              <UploadAction applicantId={applicantId} typeId={typeId} fetch={fetch} replace fileId={document.id} />
            </span>
          )}

          {!document && (
            <span>
              <UploadAction applicantId={applicantId} typeId={typeId} fetch={fetch} />
            </span>
          )}
        </>
      );
    }

    return null;
  };

  return (
    <div className={styles.wrapper}>
      <Reviewed name="documentNotes" />
      <Notes name="documentNotes" />
      <Wrapper>
        <List
          className={styles.documents}
          itemLayout="horizontal"
          dataSource={documentsGet.data || []}
          loading={documentsGet.loading}
          renderItem={(item) => (
            <List.Item actions={[getActions(item.id)]}>
              <List.Item.Meta
                avatar={<FilePdfFilled style={{ fontSize: '28px', color: COLOR_PRIMARY }} />}
                title={item.title}
                description={applicant?.documents?.find((doc) => doc.documentType?.id === item.id)?.file.originalName}
              />
            </List.Item>
          )}
        />
      </Wrapper>
    </div>
  );
}
