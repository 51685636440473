import React, {
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FormInstance, App, Form } from 'antd';
import dayjs from 'dayjs';
import {
  getArrayOfIds,
  getDateTimeISO,
  getDayJsTimeBetween,
} from '../../../../../utils';
import {
  Shift,
  ShiftCreateParams,
  ShiftUpdateParams,
  useShiftCreate,
  useShiftId,
  useShiftPublish,
  useShiftUpdate,
} from '../../../../../hooks/shift';
import { useAuthState } from '../../../../../store/auth';
import { UserRoles, isRoleEnough } from '../../../../../enums/user';
import ShiftStatus from '../../../../../enums/shift';
import { useMessageError } from '../../../../../hooks/common';
import { useDocumentRequired } from '../../../../../hooks/document';
import { generateDefaultUrl } from '../../../../../routes';
import { dateFormat } from '../../../../../contstant';
import { intervalBreaks } from '../../../Location/Components/Breaks';

interface Select {
  label: string;
  value: string;
}
interface Checkbox {
  title: string;
  value: string;
}
interface Client extends Select {
  autoApproved: boolean;
}

interface Fields {
  domains?: Select[];
  locations?: Select[];
  roles?: Select[];
  clients?: Client[];
  experiences?: Checkbox[];
  documents?: Checkbox[];
  preferredApplicants?: Checkbox[];
  applicants?: Select[];
  inductions?: Checkbox[];
}

interface ShiftActionContextProps {
  form: FormInstance | undefined;
  onCreate: () => void;
  onCreateDraft: () => void;
  onSummary: () => void;
  isSummary: boolean;
  setIsSummary: (isSummary: boolean) => void;
  summary: ShiftCreateParams | null;
  fields: Fields | null;
  setFields: (fields: Fields) => void;
  isValid: boolean;
  setValid: (isValid: boolean) => void;
  setCreateNew: (сreateNew: boolean) => void;
  isAdmin: boolean;
  loading: boolean;
  mode: string;
  onEdit: (action: string) => void;
  shift: Shift | null;
  requiredDocumentsIds: string[] | null;
}

const defaultValue: ShiftActionContextProps = {
  form: undefined,
  onCreate: () => {
    // default
  },
  onCreateDraft: () => {
    // default
  },
  onSummary: () => {
    // default
  },
  isSummary: false,
  setIsSummary: () => {
    // default
  },
  summary: null,

  fields: null,
  setFields: () => {
    // default
  },
  isValid: false,
  setValid: () => {
    // default
  },
  setCreateNew: () => {
    // default
  },
  isAdmin: false,
  loading: false,
  mode: '',
  onEdit: () => {
    // default
  },
  shift: null,
  requiredDocumentsIds: null,
};

export const ShiftActionContext = createContext<ShiftActionContextProps>(defaultValue);

interface ShiftActionProviderProps extends PropsWithChildren {
  mode: string;
}

function ShiftActionProvider({ children, mode }: ShiftActionProviderProps) {
  const shiftById = useShiftId();
  const publishShifts = useShiftPublish();
  const updateShift = useShiftUpdate();
  const createShift = useShiftCreate();
  const requiredDocuments = useDocumentRequired();
  const { message } = App.useApp();
  const [publishShift, setPublishShift] = useState<null | string>(null);
  const [isValid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSummary, setIsSummary] = useState(false);
  const [fields, setFields] = useState<Fields | null>(null);
  const [summary, setSummary] = useState<ShiftCreateParams | null>(null);
  const [form] = Form.useForm();
  const { user } = useAuthState();
  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);
  const clientId = user?.locationAdmin?.client.id || user?.manager?.client.id;
  const navigate = useNavigate();
  const [createNew, setCreateNew] = useState(false);
  const { shiftId } = useParams();
  const [shift, setShift] = useState<Shift | null>(null);
  const [searchParams] = useSearchParams();
  const [requiredDocumentsIds, setRequiredDocumentsIds] = useState<
    string[] | null
  >(null);

  useEffect(() => {
    const shiftCreateParamList = [
      'domain',
      'client',
      'locationRole',
      'location',
      'applicant',
    ];

    requiredDocuments.fetch();

    if (mode === 'create') {
      const date = dayjs(searchParams.getAll('date')[0], dateFormat);

      const props: ShiftUpdateParams = {};

      shiftCreateParamList.forEach((key) => {
        const value = searchParams.getAll(key);

        if (value.length) {
          // @ts-ignore @typescript-eslint/ban-ts-comment
          props[key] = value.length === 1 ? value[0] : value;
        }
      });

      if (dayjs(date).isValid()) {
        // @ts-ignore type warning
        props.date = [date, date];
      }
      console.log(props);

      form.setFieldsValue(props);
    }

    if (mode === 'edit' || mode === 'copy') {
      shiftById.fetch(undefined, shiftId);

      return;
    }

    form.setFieldValue('shiftType', 'instant');
    if (!isAdmin) {
      form.setFieldValue('client', clientId);
    }
  }, []);

  useEffect(() => {
    if (requiredDocuments.data) {
      setRequiredDocumentsIds(requiredDocuments?.data?.map(({ id }) => id));
    }
  }, [requiredDocuments.data]);

  useEffect(() => {
    if (shiftById.data) {
      const { data } = shiftById;

      if (!isAdmin && clientId && data.client?.id !== clientId) {
        navigate(`/shifts/${generateDefaultUrl(user)}`);
      }

      const start = data?.multiShift
        ? data.multiShift.datetimeStart
        : data?.datetimeStart || '';
      const end = data?.multiShift
        ? data.multiShift.datetimeEnd
        : data?.datetimeEnd || '';

      form.setFieldsValue({
        ...data,
        allowances:
          data.allowances?.map(({ id, type, amount }) => ({
            id,
            amount,
            type,
          })) || [],
        experiences: getArrayOfIds(data.experiences),
        applicant: data.applicant?.id,
        documents: getArrayOfIds(data.documents).filter(
          (id) => !requiredDocumentsIds?.includes(id),
        ),
        inductions: getArrayOfIds(data.inductions),
        preferredApplicants: getArrayOfIds(data.preferredApplicants),
        domain: data.domain?.id,
        client: data.client?.id,
        location: data.location?.id,
        locationRole: data.locationRole?.id,
        date: start ? getDayJsTimeBetween(start, end) : null,
        time: start ? getDayJsTimeBetween(start, end) : null,
        shiftDuration: data?.multiShift ? 'multi_day' : 'single_day',
        onCallDate:
          data?.onCallDatetimeStart && data?.onCallDatetimeEnd
            ? getDayJsTimeBetween(
              data?.onCallDatetimeStart,
              data?.onCallDatetimeEnd,
            )
            : null,
        onCallTime:
          data?.onCallDatetimeStart && data?.onCallDatetimeEnd
            ? getDayJsTimeBetween(
              data?.onCallDatetimeStart,
              data?.onCallDatetimeEnd,
            )
            : null,
        // eslint-disable-next-line no-nested-ternary
        lunchBreak: Number.isInteger(data.lunchBreak)
          ? intervalBreaks.includes(data.lunchBreak) ? data.lunchBreak : 'custom' : undefined,

        customLunchBreak: data.lunchBreak || 0,
      });
      console.log(data.lunchBreak);

      if (mode === 'copy') {
        form.setFieldsValue({
          date: null,
          time: null,
          onCallDate: null,
          onCallTime: null,
          applicant: null,
        });
      }

      if (data?.status === ShiftStatus.DRAFT) {
        form.validateFields();
      }

      setShift(data);
    }
  }, [shiftById.data, requiredDocumentsIds]);

  useEffect(() => {
    if (createShift.data) {
      if (createNew) {
        form.setFieldsValue({
          time: null,
          date: null,
        });
        setIsSummary(false);
      } else {
        navigate(-1);
      }

      message.success('Shift successfully created');
    }
  }, [createShift.data]);

  useEffect(() => {
    if (updateShift.data) {
      if (!publishShift) {
        message.success('Shift successfully updated');
        navigate(-1);
      } else {
        publishShifts.fetch({
          ids: [publishShift || ''],
        });

        setPublishShift(null);
      }
    }
  }, [updateShift.data]);

  useEffect(() => {
    if (publishShifts.data) {
      message.success('Shift successfully updated');

      navigate(-1);
    }
  }, [publishShifts.data]);

  useMessageError([createShift, updateShift, publishShifts]);

  const getFormParams = () => {
    const {
      date, time, onCallDate, onCallTime, customLunchBreak, ...values
    } = form.getFieldsValue();

    const params: ShiftCreateParams = {
      ...values,
      datetimeStart: date && time ? getDateTimeISO(date[0], time[0]) : null,
      datetimeEnd: date && time ? getDateTimeISO(date[1], time[1]) : null,
      documents: [
        ...(values.documents || []),
        ...(requiredDocuments?.data?.map(({ id }) => id) || []),
      ],
      // eslint-disable-next-line no-nested-ternary
      lunchBreak: Number.isInteger(values.lunchBreak)
        ? values.lunchBreak : values.lunchBreak === 'custom' ? customLunchBreak : null,

    };

    if (onCallDate && onCallTime) {
      params.onCallDatetimeStart = getDateTimeISO(onCallDate[0], onCallTime[0]) || '';
      params.onCallDatetimeEnd = getDateTimeISO(onCallDate[1], onCallTime[1]) || '';
    }

    return params;
  };

  const onSummary = () => {
    form
      .validateFields()
      .then(() => {
        setIsSummary(true);
        setSummary(getFormParams());
      })
      .catch(() => {
        setValid(false);
      });
  };

  const getStatus = () => {
    const isAutoApproved = fields?.clients?.find(
      (item) => item.value === form.getFieldValue('client').autoApproved,
    );
    const shiftType = form.getFieldValue('shiftType');
    const applicant = form.getFieldValue('applicant');

    if (isAdmin && applicant) {
      return ShiftStatus.FILLED;
    }

    if (isAdmin || isAutoApproved) {
      if (shiftType === 'client review') {
        return ShiftStatus.REQUEST;
      }

      return ShiftStatus.OPEN;
    }

    return ShiftStatus.APPROVAL;
  };

  useEffect(() => {
    setLoading(createShift.loading || updateShift.loading);
  }, [createShift.loading, updateShift.loading]);

  const onCreate = () => {
    createShift.fetch({ ...summary!, status: getStatus() });
  };

  const onCreateDraft = () => {
    createShift.fetch({ ...getFormParams(), status: ShiftStatus.DRAFT });
  };

  const onEdit = (action: string) => {
    if (shift?.status === ShiftStatus.REJECTED) {
      updateShift.fetch({ ...summary!, status: getStatus() }, shiftId);
    } else {
      updateShift.fetch(summary!, shiftId);
      if (action === 'publish') {
        setPublishShift(shiftId || '');
      }
    }
  };

  return (
    <ShiftActionContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        form,
        onCreate,
        onCreateDraft,
        onSummary,
        isSummary,
        setIsSummary,
        summary,
        fields,
        setFields,
        isValid,
        setValid,
        setCreateNew,
        loading,
        isAdmin,
        mode,
        onEdit,
        shift,
        requiredDocumentsIds,
      }}
    >
      {children}
    </ShiftActionContext.Provider>
  );
}

export default ShiftActionProvider;

export const useShiftActionContext = (): ShiftActionContextProps => React.useContext(ShiftActionContext);
